import { useCallback, useState } from "react";
import FormInput from "../components/FormInput";
import { axiosPost } from "../lib/axiosPost";
// import { login } from "../features/auth/authSlice";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [eye, setEye] = useState(false);
  const [type, setType] = useState("password");
  const handleInput = (e, name) => {
    setFormData((prev) => ({ ...prev, [name]: e.target.value }));
  };
  const handleEye = () => {
    setEye((prev) => !prev);
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const data = await axiosPost("/api/user/register", formData);

      console.log(data);

      if (data?.status === 404) {
        setError(data?.error);
        setIsLoading(false);
      }
      if (data === "success") {
        setIsLoading(false);
        setFormData({
          name: "",
          email: "",
          password: "",
        });
        // dispatch(login(data));
        toast.success("Register successfull.");
        navigate("/login");
      }
    },
    [formData, navigate]
  );

  return (
    <div className="flex justify-center items-center min-h-[calc(100vh-12rem)] w-full  px-2 md:px-0 ">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-lg rounded-md p-4 max-w-[25rem] w-[24rem] h-full flex flex-col justify-center items-center"
      >
        <div className="form-header flex flex-col items-center justify-center">
          <h2 className="text-3xl font-semibold text-[#49280D]">
            Create An Account
          </h2>
          <div className="w-48 overflow-hidden">
            <img src="logo.png" alt="logo" />
          </div>
        </div>
        <FormInput
          label={"Name"}
          type={"text"}
          value={formData.name}
          name={"name"}
          placeholder={"Enter Your Name"}
          onChange={(e) => handleInput(e, "name")}
        />
        <FormInput
          label={"Email"}
          type={"email"}
          value={formData.email}
          name={"email"}
          placeholder={"Enter Your Email"}
          onChange={(e) => handleInput(e, "email")}
        />
        <FormInput
          label={"Password"}
          type={type}
          value={formData.password}
          name={"password"}
          placeholder={"Enter Your Password"}
          onChange={(e) => handleInput(e, "password")}
          onClick={handleEye}
          eye={eye}
        />
        {error && <p className="text-red-500">{error}</p>}
        <button className="bg-[#D89307] w-full text-white py-2 mt-2 rounded-full">
          <span className="flex justify-center items-center gap-2">
            {isLoading && (
              <MoonLoader
                color="rgb(105, 71, 4)"
                loading={isLoading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            Register
          </span>
        </button>
      </form>
    </div>
  );
};

export default SignUp;
