import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSubtotal,
  removeAllFromCart,
  removeFromCart,
} from "../features/products/cartSlice";

import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { shippingChargeFetching } from "../features/products/shippingChargeSlice";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const { items: shippingCharge } = useSelector(
    (state) => state.shippingCharge
  );
  const { userAndToken: userData } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const [orderItem] = useState(data);
  // const [response, setResponse] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(shippingChargeFetching());
  }, [dispatch]);

  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: userData ? userData?.user?.name : "",
    email: userData ? userData?.user?.email : "",
    phone: userData ? userData?.user?.phone : "",
    address: userData ? userData?.user?.address : "",
    shipping_cost: "",
    shipping_type: "Cash On Delivery",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        setLoading(true);
        const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: orderItem,
            shipping_cost: formData.shipping_cost,
            shipping_type: "Cash On Delivery",
            grand_total,
          }),
        });

        if (!res.ok) {
          setLoading(true);
          throw new Error("Something Went Error");
        }
        if (res.ok) {
          setLoading(false);
          dispatch(removeAllFromCart());
          navigate("/successpage");
        }
      } catch (err) {
        setLoading(true);
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "SSL Commerz") {
      const queryParams = new URLSearchParams({
        name: formData.name,
        item: JSON.stringify(orderItem),
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        grand_total,
        shipping_type: formData.shipping_type,
        shipping_cost: formData.shipping_cost,
      });

      window.location.href = `${process.env.REACT_APP_URL}/example2/?${queryParams}`;
    }
  };

  const handleRemove = (product) => {
    dispatch(removeFromCart(product));
  };

  const shippingtype = (e) =>
    setFormData({
      ...formData,
      shipping_type: e.target.value,
    });

  // const [couponCode, setCouponCode] = useState("");

  // const handleCouponCodeChange = (event) => {
  //   setCouponCode(event.target.value);
  // };
  // const applyCoupon = async () => {
  //   setCouponCode("");
  //   try {
  //     await fetch(`https://mehendibymimi.com/admin/public/api-coupon-code`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         coupon: couponCode,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setResponse(data);
  //       });
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };
  // let grand_total = response.discount
  //   ? cartTotalAmount + +formData.shipping_cost + -response.discount
  //   : cartTotalAmount + +formData.shipping_cost + 0;

  let grand_total = cartTotalAmount + +formData.shipping_cost;
  return (
    <>
      <div className="checkout-page px-2 mt-10 mb-10 container min-h-screen mx-auto grid grid-cols-1 md:grid-cols-2 ">
        <form onSubmit={handleFormSubmit}>
          <div className="billing-address flex flex-col order-2 md:order-1">
            <h2 className="text-2xl mt-5 md:mt-0 mb-5">Billing Address</h2>
            <div className="form-control flex flex-col gap-2  mb-3">
              <label className="mb-2">Full Name</label>
              <input
                required
                type="text"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    name: userData?.user?.name
                      ? userData?.user?.name
                      : e.target.value,
                  })
                }
                readOnly={userData?.user?.name}
                value={formData.name}
                className="px-3 py-2  rounded-md outline-none border focus:border-[#9A2A55] duration-300"
                placeholder="Enter Your First Name"
              />
            </div>
            <div className="form-control flex flex-col mb-3">
              <label className="mb-2">Phone Number</label>
              <input
                required
                type="tel"
                pattern="[0-9]{11}"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phone: userData?.user?.phone
                      ? userData?.user?.phone
                      : e.target.value,
                  })
                }
                readOnly={userData?.user?.phone}
                value={formData.phone}
                className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#9A2A55] duration-300"
                placeholder="Enter Your Number"
              />
            </div>
            <div className="form-control flex flex-col mb-3">
              <label className="mb-2">
                Email Address (<span className="text-rose-500">optional</span>)
              </label>
              <input
                className="px-3 py-2  rounded-md outline-none border focus:border-[#9A2A55] duration-300"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: userData?.user?.email
                      ? userData?.user?.email
                      : e.target.value,
                  })
                }
                readOnly={userData?.user?.email}
                value={formData.email}
                type="email"
                placeholder="Enter Your Email..."
              />
            </div>

            <div className="form-control flex flex-col  mb-3 ">
              <label className="mb-2">Address</label>
              <input
                required
                type="text"
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                value={formData.address}
                className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#9A2A55] duration-300 resize-none"
                placeholder="Enter Your Address"
              />
            </div>
            {/* <div className="form-control flex flex-col  mb-3 ">
            <label className="mb-2">Shipping Cost</label>
            <textarea
              required
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, shipping_cost: e.target.value })
              }
              value={formData.shipping_cost}
              className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
              placeholder="Enter Your shipping cost"
            />
          </div> */}
            <div className="form-control flex flex-col gap-2  mb-3">
              <select
                required
                onChange={(e) =>
                  setFormData({ ...formData, shipping_cost: e.target.value })
                }
                className="px-3 py-2  rounded-md outline-none border focus:border-[#9A2A55] duration-300"
              >
                <option value="">Select Area</option>
                {shippingCharge?.map((charge) => (
                  <option key={charge.id} value={charge.amount}>
                    {charge.name} ({charge.amount})
                  </option>
                ))}
                {/* <option value="8">Dhaka Subcity(8)</option>
                <option value={100}>Outside Dhaka (100)</option> */}
              </select>
            </div>

            <div className="block md:hidden py-4 text-[#9A2A55]">
              <span>পেমেন্ট মেথড সিলেক্ট করুন</span>
            </div>

            <div className="block md:hidden ">
              <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 ">
                {/* <div className="flex items-center gap-3">
              <input
                required
                onChange={(e) =>
                  setFormData({ ...formData, shipping_type: e.target.value })
                }
                type="checkbox"
                value="Cash On Delivery"
                name=""
                id=""
              />
              <span className="text-[0.9rem] text-[#212121]">
                Cash On Delivery
              </span>
            </div> */}
                <div>
                  <label className="cursor-pointer">
                    <input
                      type="radio"
                      onChange={shippingtype}
                      name="shipping_type"
                      checked
                      value={"Cash On Delivery"}
                      className="peer sr-only"
                    />
                    <div className="w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                      <img
                        src="/cash_on_delivery.jpg"
                        alt="cash_on_delivery"
                        className="w-full h-12 "
                      />
                    </div>
                  </label>
                </div>
                {/* <div>
                  <label className="cursor-pointer relative">
                    <input
                      type="radio"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          shipping_type: e.target.value,
                        })
                      }
                      className="peer sr-only"
                      name="shipping_type"
                      value={"SSL Commerz"}
                    />
                    <div className=" w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                      <img src="./ssl.jpg" alt="" className="w-full h-12 " />
                    </div>
                  </label>
                </div> */}
              </div>
            </div>

            <div className="form-control flex items-end justify-between  my-5">
              <div className="flex">
                <button
                  type="submit"
                  disabled={loading}
                  className={` ${
                    loading ? " bg-gray-600 text-white " : " bg-[#9A2A55]"
                  }   px-5 py-3 rounded-md`}
                >
                  {loading ? "Ordering..." : "Place Order"}
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="order-details md:ml-40 ml-0 md:order-2 order-1">
          <h2 className="text-2xl">Order Details</h2>

          <div className="h-auto overflow-y-scroll order-details-container">
            {data?.map((product) => (
              <div
                key={product.id}
                className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden"
              >
                <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                    className="w-full object-cover "
                    alt=""
                  />
                </div>
                <div className="cart-description w-full overflow-hidden ">
                  <div className="flex flex-col">
                    <p className="truncate">
                      {product?.name.substring(0, 60)}...
                    </p>
                    <div className="cart-price">৳{product?.variantPrice}</div>
                    <button
                      className="text-base hover:text-red-500 duration-500 justify-center"
                      onClick={() => handleRemove(product)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="hidden md:block py-4 text-[#9A2A55">
            <span>পেমেন্ট মেথড সিলেক্ট করুন</span>
          </div>
          <div className="hidden md:block">
            <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 ">
              {/* <div className="flex items-center gap-3">
              <input
                required
                onChange={(e) =>
                  setFormData({ ...formData, shipping_type: e.target.value })
                }
                type="checkbox"
                value="Cash On Delivery"
                name=""
                id=""
              />
              <span className="text-[0.9rem] text-[#212121]">
                Cash On Delivery
              </span>
            </div> */}
              <div>
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    onChange={shippingtype}
                    checked
                    name="shipping_type"
                    value={"Cash On Delivery"}
                    className="peer sr-only"
                  />
                  <div className="w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                    <img
                      src="/cash_on_delivery.jpg"
                      alt="cash_on_delivery"
                      className="w-full h-12 "
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="payment-summary flex flex-col gap-3 mt-5 ">
            <div className="sub-total flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold">
                Sub Total
              </h4>
              <span className="text-slate-700 ">৳{cartTotalAmount}</span>
            </div>
            <div className="shipping flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold ">
                Shipping
              </h4>
              <span className="text-slate-700 ">
                ৳{+formData.shipping_cost}
              </span>
            </div>
            {/* <div className="shipping flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold ">
                Discount
              </h4>
              <span className="text-slate-700 ">
                {response.discount
                  ? currencyFormatter(+response.discount)
                  : currencyFormatter(0)}
              </span>
            </div> */}
            <div className="sub-total flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold">Total</h4>
              <span className="text-slate-700 font-semibold ">
                ৳{grand_total}
              </span>
            </div>
            {/* <div>
              <div className="flex   gap-3">
                <input
                  type="text"
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                  className="px-3 py-2 xl:w-72  w-60  rounded-md outline-none border"
                />
                <button
                  onClick={applyCoupon}
                  className="bg-[#B88313] px-3  text-sm rounded-md"
                >
                  Apply Coupon
                </button>
              </div>
              {response.status === 200 && (
                <p className="text-green-500 text-xs mt-2">
                  {response.massage}
                </p>
              )}
              {response.status === 404 && (
                <p className="text-rose-500 text-xs mt-2">{response.massage}</p>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
